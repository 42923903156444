<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import togglePassword from "@/helper/togglePassword";
export default {
    name: "ResetPassword",
    data() {
        return {
            loading: false,
            password: "",
            confirmPassword: "",
            id: "",
            timestamp: "",
            time_duration: ""
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.timestamp = this.$route.params.timestamp;
        this.time_duration = this.$route.params.time_duration;
    },
    methods: {
        validateState(name) {
            return this.$v[name].$dirty? (!this.$v[name].$invalid? null:false) :null
        },
        togglePassword:togglePassword,
        handleSubmit() {
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$refs.submitButton.disabled=true;
            this.loading = true
            try {
                this.$http
                    .post("/reset-password", {
                        id: this.id,
                        password: this.password,
                        confirm_password: this.confirmPassword
                    })
                    .then(response => {
                        if (
                            response.data &&
                            response.data.status &&
                            response.data.status === "success"
                        ) {
                            // alert("Correct Username & Password!");
                            // console.log("False", response.data.status);
                            this.$notify({
                                title: "SUCCESS!",
                                text: "Password changed successfully",
                                type: "success"
                            });
                            this.$router.push({path:'/login'});
                        } else if (
                            response.data &&
                            response.data.status &&
                            response.data.status == "fail"
                        ) {
                            this.$notify({
                                title: "Sorry",
                                text: "Invalid ID or Expired!",
                                type: "error"
                            });
                        }
                        this.loading = false
                    })
                    .catch(function(error) {
                        this.loading = false
                        console.warn(error);
                    })
            } catch (e) {
                console.warn(e);
            }
        }
    },
    validations: {
        password: { required, minLength: minLength(6) },
        confirmPassword: { sameAsPassword: sameAs("password") }
    }
};
</script>

<template>
   <b-container class="page reset-password">
      <div class="content-wrap narrow">
         <h1 class="text-center">Reset Password</h1>
         <b-form @submit.stop.prevent="handleSubmit">
            <b-form-group label="Password">
               <b-input-group>
                  <b-form-input
                     type="password"
                     placeholder
                     v-model="password"
                     @keyup="$v.password.$touch()"
                     :state="validateState('password')"
                     ></b-form-input>
                  <b-input-group-append>
                     <b-button type="button" class="view-password-btn" variant="outline-primary" @click="togglePassword($event.target)">
                        <i class="material-icons"  style="display:none">visibility</i>
                        <i class="material-icons" style="display:inline-block">visibility_off</i>
                     </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback
                     >Password is required, Password must have at least {{ $v.password.$params.minLength.min }} characters.</b-form-invalid-feedback>
               </b-input-group>
            </b-form-group>
            <b-form-group label="Confirm Password">
               <b-input-group>
                  <b-form-input
                     type="password"
                     placeholder
                     v-model="$v.confirmPassword.$model"
                     :state="validateState('confirmPassword')"
                     />
                  <b-input-group-append>
                     <b-button type="button" class="view-password-btn" variant="outline-primary" @click="togglePassword($event.target)">
                        <i class="material-icons"  style="display:none">visibility</i>
                        <i class="material-icons" style="display:inline-block">visibility_off</i>
                     </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback>Passwords must be identical.</b-form-invalid-feedback>
               </b-input-group>
            </b-form-group>
            <b-form-group class="text-center">
               <b-button variant="primary" type="type" ref="submitButton" block >
                   <b-spinner v-if="loading" small label="Spinning"></b-spinner>
                   Submit
                </b-button>
            </b-form-group>
         </b-form>
      </div>
   </b-container>
</template>